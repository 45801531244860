import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { GridFeed } from '@desktop/layouts/GridFeedlLayout'
import useKeyboardShortcut from '@hooks/useKeyboardShortcut'
import IconArrowMin from '@icons/IconArrowMin.svg'
import { keyForResource } from '@models/APIResource'
import { IntoNode } from '@models/IntoNode'
import { filterResourcesByType } from '@models/RecommendResponse'
import feedApi from '@redux/api/feedApi'
import { mergeCurrentContext } from '@redux/slices/analyticsSlice'
import { selectMediaTypes, setPageSection } from '@redux/slices/appSlice'
import {
  feedConstants,
  setCurrentFeedItemKey,
  setIdentifier,
  setInstanceId,
  setIsRelatedVisible,
  setPageNumber,
  setPageSize,
  setStreamUrlId,
} from '@redux/slices/feedSlice'
import { setIsFiltersEnabled, setSharingEnabled, setUrlActionsEnabled } from '@redux/slices/headerSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'

export const UrlRelatedGrid = () => {
  const dispatch = useAppDispatch()
  const { isRelatedVisible } = useAppSelector(state => state.feed)
  const gridRef = useRef<HTMLDivElement>(null)
  const { ref, inView } = useInView({ initialInView: false, threshold: 1 })

  const onClose = useCallback(() => {
    dispatch(setUrlActionsEnabled(true))
    dispatch(setIsFiltersEnabled(true))
    dispatch(setSharingEnabled(true))
    gridRef.current?.scrollTo(0, 0)
    gridRef.current?.scrollIntoView({
      block: 'end',
      behavior: 'smooth',
    })
  }, [dispatch])

  useKeyboardShortcut({
    codes: {
      Escape: onClose,
    },
  })

  const currentFeedItemKey = useAppSelector(state => state.feed.currentFeedItemKey)
  const _instanceId = `related-${currentFeedItemKey}`

  const router = useRouter()
  const mediaTypes = useAppSelector(selectMediaTypes)
  const node = IntoNode.RELATED
  const contextId = currentFeedItemKey ?? null
  const page = useRef(1)
  const [filterIds, setFilterIds] = useState<(string | number)[]>([])

  const {
    data: feed,
    isLoading,
    isFetching,
    isUninitialized,
  } = feedApi.useGetFeedDataQuery(
    {
      page: page.current,
      pageSize: feedConstants.RELATED_GRID_PAGE_SIZE,
      contextId,
      slug: node.slug,
      mediaTypes: Array.from(mediaTypes),
      _instanceId,
      filterIds: currentFeedItemKey ? [currentFeedItemKey, ...filterIds] : filterIds,
    },
    { skip: !currentFeedItemKey }
  )
  const { items } = feed ?? {}

  useEffect(() => {
    const ids = items?.reduce(
      (acc, item) => {
        const key = keyForResource(item)
        if (key) {
          acc.push(key)
        }
        return acc
      },
      [] as (string | number)[]
    )
    if (ids) setFilterIds(ids)
  }, [items, setFilterIds])

  const isRelatedLoading = isLoading || isFetching || isUninitialized
  const [isLoadingNewFeed, setIsLoadingNewFeed] = useState(true)

  useEffect(() => {
    setIsLoadingNewFeed(true)
    page.current = 1
  }, [contextId])

  useEffect(() => {
    if (isLoadingNewFeed && !isRelatedLoading) setIsLoadingNewFeed(false)
  }, [isLoadingNewFeed, isRelatedLoading])

  const urls = filterResourcesByType(items, 'url').filter(url => url.url_id !== currentFeedItemKey)

  useEffect(() => {
    const RelatedOpenEvent = new CustomEvent('relatedOpen')
    const RelatedClosedEvent = new CustomEvent('relatedClose')

    if (inView && !isRelatedVisible) {
      dispatch(setIsRelatedVisible(true))
      document.dispatchEvent(RelatedOpenEvent)
    } else if (!inView && isRelatedVisible) {
      dispatch(setIsRelatedVisible(false))
      document.dispatchEvent(RelatedClosedEvent)
    }
  }, [dispatch, inView, isRelatedVisible])

  const itemClickHandler = useCallback(
    (feedItemKey: string) => {
      onClose()
      dispatch(
        mergeCurrentContext({
          pageSection: null,
          streamType: 'RELATED_ARTICLE_STREAM',
        })
      )
      dispatch(setInstanceId(_instanceId))
      dispatch(setIdentifier(node))
      dispatch(setPageSection(null))
      dispatch(setPageSize(feedConstants.RELATED_GRID_PAGE_SIZE))
      dispatch(setPageNumber(1))
      dispatch(setStreamUrlId(currentFeedItemKey ?? null))
      const queryString = `?skipSSR=true`
      const asPath = `/!${feedItemKey}`
      const href = `/!${feedItemKey}${queryString}`
      dispatch(setCurrentFeedItemKey(feedItemKey))
      router.push(href, asPath).catch(() => {})
    },
    [_instanceId, onClose, currentFeedItemKey, dispatch, node, router]
  )

  if (!isRelatedLoading && urls.length === 0) return null
  return (
    <>
      <button
        onClick={() => {
          if (isRelatedVisible) {
            gridRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
            gridRef.current?.parentElement?.scrollTo({ top: 0, behavior: 'smooth' })
          } else gridRef.current?.scrollIntoView({ behavior: 'smooth' })
        }}
        className={[
          'btn fixed bottom-5 left-1/2 z-50 flex -translate-x-1/2 items-center bg-secondary text-sm font-normal text-primary/70 transition-all duration-300 ease-in-out',
          isLoadingNewFeed ? 'opacity-0' : 'opacity-100',
        ].join(' ')}
      >
        <span className="px-2">{isRelatedVisible ? 'Back to top' : 'You might also like'}</span>
        <IconArrowMin
          className={`size-6 p-1 transition-transform duration-300 ease-in-out ${isRelatedVisible ? 'rotate-90' : '-rotate-90'}`}
        />
      </button>
      <div ref={gridRef} className="h-20 snap-start px-6">
        <div ref={ref} className="h-[calc(100vh-73px)] overflow-y-scroll scrollbar-hide">
          <GridFeed
            loadMore={() => {
              page.current++
            }}
            items={isLoadingNewFeed ? [] : urls}
            onClick={itemClickHandler}
            isLoadingFeed={isRelatedLoading}
            pageSection={`node.${node.slug}`}
            page={page.current}
            hasReachedEnd={false}
            node={node.slug}
            streamUrlId={currentFeedItemKey}
          />
        </div>
      </div>
    </>
  )
}
