import { useRouter } from 'next/router'
import React, { createRef, useEffect } from 'react'
import { XBlock, XMasonry } from 'react-xmasonry'

import { BlockWidthConfigs, useTargetBlockWidth } from '@hooks/useTargetBlockWidth'
import { IntoUrl } from '@models/IntoUrl'
import { useAppSelector } from '@redux/store/store'
import { UrlGridElements } from './UrlGrid'
import { UrlGridItemLoading } from './UrlGridItem'

export interface MasonryBreakpoints extends Record<string, number> {
  default: number
  '1800': number
  '1200': number
  '1050': number
  '500': number
}

interface MasonryLayout {
  urls: IntoUrl[]
  loadingItems?: number
  likedByUsername?: string | undefined
  onClick?: (index: string) => void
  isTitleEnabled?: boolean
  pageSection: string
  node: string
  streamUrlId: string | number | null
}

const FALLBACK_LOADING_ROWS = 4
export const UrlMasonryGridItemsLoading = (limit: number) => {
  return new Array(limit).fill(undefined).map((el, index) => {
    let height: string
    // eslint-disable-next-line no-magic-numbers
    switch (index % 3) {
      case 0:
        height = 'h-56'
        break
      case 1:
        height = 'h-48'
        break
      default:
        height = 'h-64'
        break
    }
    return (
      <XBlock key={`urls-loading-${index}-block`}>
        <div className="m-1.5">
          <UrlGridItemLoading height={height} key={`urls-loading-${index}`} />
        </div>
      </XBlock>
    )
  })
}
export const UrlMasonryGrid = ({
  urls,
  loadingItems,
  likedByUsername,
  onClick,
  isTitleEnabled = true,
  pageSection,
  node,
  streamUrlId,
}: MasonryLayout) => {
  const targetBlockWidth = useTargetBlockWidth()
  const maxImageWidth = targetBlockWidth
    ? Math.min(BlockWidthConfigs.md.maxWidth, targetBlockWidth) // Don't make the image too small.
    : BlockWidthConfigs.md.maxWidth
  const scrollableRef = createRef<{ container?: HTMLDivElement; columns: number }>()
  const isChangingFeed = useAppSelector(state => state.feed.isChangingFeed)

  const gridElements = UrlGridElements({
    urls,
    layout: 'masonry',
    isTitleEnabled,
    maxImageWidth,
    onClick,
    likedByUsername,
    pageSection,
    node,
    streamUrlId,
  })

  const loadingElements = loadingItems ? (
    UrlMasonryGridItemsLoading(loadingItems * (scrollableRef.current?.columns ?? FALLBACK_LOADING_ROWS))
  ) : (
    <></>
  )

  const router = useRouter()
  useEffect(() => {
    const prefetchPromises = urls.map(async url => {
      if (!url.url_id) return Promise.resolve()
      const path = `/!${url.url_id}`
      return router.prefetch(path).catch(() => {})
    })

    Promise.allSettled(prefetchPromises).catch(() => {})
  }, [urls, router])

  return targetBlockWidth ? (
    <XMasonry
      // @ts-expect-error – XMasonry is using legacy refs
      ref={scrollableRef}
      smartUpdate={true}
      targetBlockWidth={targetBlockWidth}
      responsive={false}
    >
      {!isChangingFeed && gridElements}
      {loadingElements}
    </XMasonry>
  ) : (
    <></>
  )
}
