import { ReactNode, useEffect, useRef, useState } from 'react'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import NodePill from '@desktop/NodePill'
import { useIsDesktop } from '@hooks/useIsDesktop'
import { IntoUrl } from '@models/IntoUrl'
import { UrlComments } from '@pages/url/components/UrlComments'
import { UrlLikers } from '@pages/url/components/UrlLikers'
import { UrlMeta, UrlMetaLoading } from '@pages/url/components/UrlMeta'
import { selectIntoConfig } from '@redux/slices/appSlice'
import { useAppSelector } from '@redux/store/store'
import { UrlContextUser } from './UrlContextUser'
import { UrlShareDetails } from './UrlShareDetails'

interface UrlSidePanelProps {
  url: IntoUrl | undefined | null
}

const UrlSidePanelSection = ({ children, className }: { children: ReactNode; className?: string }) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    // Check if children exist and have height
    if (contentRef.current) {
      const hasChildren = contentRef.current.children.length > 0
      const hasHeight = contentRef.current.clientHeight > 0
      setIsVisible(hasChildren && hasHeight)
    }
  }, [children])

  if (!isVisible) return null

  return (
    <div ref={contentRef} className={`space-y-8 rounded-3xl bg-contrast/5 ${className}`}>
      {children}
    </div>
  )
}

export const UrlSidePanel = ({ url }: UrlSidePanelProps) => {
  const { isLoggedIn } = useCurrentUserContext()
  const isDesktop = useIsDesktop()
  const likedByUsername = useAppSelector(state => state.user.likedByUsername)
  const intoConfig = useAppSelector(selectIntoConfig)
  const curatorUserId = url?.meta.curatorUserID
  return (
    <div className="box-border h-full space-y-8">
      <div className="flex flex-col justify-between space-y-8 px-6">
        <div className="h-fit space-y-2 pb-6">
          <UrlSidePanelSection className="p-6">
            {url ? (
              <>
                <UrlMeta url={url} />
                {curatorUserId && <UrlContextUser userPayload={{ userId: curatorUserId }} heading="Posted by" />}
                {!curatorUserId && likedByUsername && (
                  <UrlContextUser userPayload={{ username: likedByUsername }} heading="Liked by" />
                )}
                {url && isDesktop && <UrlShareDetails url={url} />}
                <UrlLikers url={url} likedByUsername={likedByUsername} />
              </>
            ) : (
              <UrlMetaLoading />
            )}
          </UrlSidePanelSection>

          {url?.nodes?.length && url?.nodes?.length > 0 ? (
            <UrlSidePanelSection className="p-6">
              <div className="flex flex-wrap">
                {url.nodes.map(node => (node ? <NodePill key={node.slug} slug={node.slug} /> : null))}
              </div>
            </UrlSidePanelSection>
          ) : (
            <></>
          )}

          {url && isDesktop && (intoConfig?.displayOptions?.showComments || !isLoggedIn) ? (
            <UrlSidePanelSection className="p-0">
              <UrlComments className="!rounded-3xl !bg-transparent" url={url} />
            </UrlSidePanelSection>
          ) : (
            <></>
          )}
        </div>
        {isDesktop && !isLoggedIn && <div className="h-52" />}
      </div>
    </div>
  )
}
