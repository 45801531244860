import Image from 'next/image'
import Link from 'next/link'
import React, { ReactElement } from 'react'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useTrackClick } from '@hooks/analytics/useTrackClick'
import UrlHelper from '@lib/UrlHelper'
import { useToggleUserFollow } from '@pages/user/hooks/useToggleUserFollow'
import userApi from '@redux/api/userApi'
import { FollowButtonSmall } from './FollowButton'

export const UserListItemLoading = () => (
  <div className="flex animate-pulse items-center space-x-4">
    <span className="inline-block aspect-square w-11 shrink-0 rounded-full bg-contrast/10"></span>
    <div className="flex flex-1 flex-col">
      <span className="mb-2 block h-4 w-[150px] rounded bg-contrast/10"></span>
      <span className="mb-1 block h-3 w-[90px] rounded bg-contrast/10 opacity-75"></span>
      <span className="block h-3 w-[80px] rounded bg-contrast/10 opacity-50"></span>
    </div>
  </div>
)

interface UserInfoProps {
  userId: number
  className?: string
  children?: ReactElement
  hideFollowButton?: boolean
  preventNavigation?: boolean
}

export const UserListItem = ({ userId, className, children, hideFollowButton, preventNavigation }: UserInfoProps) => {
  const { currentUserID } = useCurrentUserContext()
  const { data: user } = userApi.useGetUserDetailsQuery({ userId })
  const handleFollow = useToggleUserFollow(user)
  const trackClick = useTrackClick()

  if (!user) return null
  return (
    <div className={`relative flex flex-row items-center space-x-4 ${className ?? ''}`}>
      <Link
        href={UrlHelper.userPath(user.username)}
        onClick={event => {
          if (preventNavigation) event.preventDefault()
          trackClick('userItem')
        }}
        className="relative z-10 flex min-w-0 flex-1 items-center space-x-4"
      >
        <Image
          className="inline-block aspect-square w-14 shrink-0 rounded-full bg-contrast/10 object-cover"
          src={user.photo_url ?? ''}
          alt={user.display_name}
          height={44}
          width={44}
        />
        {children ? (
          children
        ) : (
          <div className="flex min-w-0 flex-1 flex-col">
            <p className="truncate font-medium">{user.display_name}</p>{' '}
            <p className="mb-1 truncate text-sm opacity-50">{`@${user.username}`}</p>{' '}
          </div>
        )}
      </Link>
      {currentUserID !== user.user_id && !hideFollowButton && (
        <div className="relative z-20">
          <FollowButtonSmall
            isFollowing={user.meta.isLiked ?? false}
            onFollow={handleFollow}
            deeplinkUrl={UrlHelper.userPermalink(user.username)}
          />
        </div>
      )}
    </div>
  )
}
