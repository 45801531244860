import Image from 'next/image'
import React, { useMemo, useRef } from 'react'

import { UserList, UserListRef } from '@desktop/UserList'
import { IntoUrl } from '@models/IntoUrl'
import urlApi from '@redux/api/urlApi'

const FOLLOWING_LIKER_LIMIT = 1

interface UrlLikersProps {
  url: IntoUrl
  likedByUsername?: string | null
}

export const UrlLikers: React.FC<UrlLikersProps> = ({ url, likedByUsername }) => {
  const { isLoading: isLoadingLikers, data: dataLikers = [] } = urlApi.useGetLikersQuery({
    urlId: url.url_id,
    cookie: null,
  })
  const userListRef = useRef<UserListRef>(null)

  const followingLikers = useMemo(
    () => dataLikers.filter(liker => liker.meta.isLiked).slice(0, FOLLOWING_LIKER_LIMIT),
    [dataLikers]
  )

  const likers = useMemo(
    () => dataLikers.filter(liker => liker.username !== likedByUsername),
    [dataLikers, likedByUsername]
  )

  const hasFollowedLikers = followingLikers?.length > 0
  const remainingLikers = likers?.length - followingLikers?.length
  const hasAdditionalLikers = remainingLikers > 0
  const likerText = useMemo(() => {
    if (hasFollowedLikers) {
      const displayNames = [...followingLikers.map(liker => liker.display_name)]
      if (hasAdditionalLikers) return displayNames.join(', ').concat(' and ')
      return followingLikers?.length > 1
        ? [displayNames.pop(), displayNames.join(', ')].reverse().join(' and ')
        : displayNames
    }
  }, [hasFollowedLikers, hasAdditionalLikers, followingLikers])
  const additionalLikersButton = useMemo(() => {
    return hasAdditionalLikers ? (
      <>
        {'+'}
        {remainingLikers}
      </>
    ) : null
  }, [hasAdditionalLikers, remainingLikers])

  const likerHeader = useMemo(
    () => (
      <div
        onClick={() => userListRef.current?.toggleUserModalVisibility()}
        className="cursor-pointer text-[13px] text-primary underline decoration-[#F0F0F0]"
      >
        {likerText} {additionalLikersButton} liked this
      </div>
    ),
    [likerText, additionalLikersButton]
  )

  if (isLoadingLikers || likers?.length === 0) return null
  return (
    <div className="flex items-center">
      <div className="hidden">
        <UserList ref={userListRef} users={likers} />
      </div>
      <button
        onClick={() => userListRef.current?.toggleUserModalVisibility()}
        className="mr-2 flex h-6 max-w-[60px] cursor-pointer flex-wrap -space-x-3 overflow-hidden"
      >
        {likers.map(user => (
          <Image
            key={user.user_id}
            className="inline-block aspect-square size-6 shrink-0 rounded-full border-[.6px] border-secondary bg-tertiary object-cover"
            src={user.photo_url ?? ''}
            alt={user.display_name}
            height={44}
            width={44}
          />
        ))}
      </button>
      {likerHeader}
    </div>
  )
}
