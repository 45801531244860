import axios from 'axios'
import React, { useEffect } from 'react'

import Modal from '@common/Modal'
import { useSegmentTrack } from '@hooks/analytics/useSegmentAnalytics'
import { useTrackClick } from '@hooks/analytics/useTrackClick'
import { decrementViewsLeftForNSFWPrompt } from '@redux/slices/appSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'
import MixMix from '@services/MixMix'

export const NSFWPromptModal = () => {
  const dispatch = useAppDispatch()
  const track = useSegmentTrack()
  const trackClick = useTrackClick()
  const { viewsLeftForNSFWPrompt } = useAppSelector(state => state.app)
  const onClose = () => {
    dispatch(decrementViewsLeftForNSFWPrompt())
  }

  useEffect(() => {
    if (viewsLeftForNSFWPrompt === 0) track('modalView', { modalType: 'NSFWPromptModal' })
  }, [track, viewsLeftForNSFWPrompt])

  return (
    <Modal isVisible={viewsLeftForNSFWPrompt === 0} onClose={onClose} modalType="NSFWPromptModal">
      <div className="xs:w-[30rem] relative z-10 flex max-h-screen w-full max-w-md flex-col rounded bg-menu p-6 text-center text-menu">
        <div className="self-center text-2xl font-semibold">
          Would you like to get <br />
          NSFW recommendations?
        </div>
        <div className="mb-6 mt-3 space-y-3 font-medium opacity-60">
          <div>
            If you enable this option, you may occasionally get suggestive/Not Safe For Work (NSFW) content recommended
            on Mix.
          </div>
          <div> You can change this later in Settings</div>
        </div>
        <div className="flex justify-center space-x-6">
          <button
            onClick={() => {
              onClose()
              trackClick('dismiss', { modalType: 'NSFWPromptModal', buttonType: 'Not Now' })
            }}
            className="btn cursor-pointer bg-button-secondary/50 text-primary"
          >
            Not Now
          </button>
          <button
            onClick={() => {
              void axios.request(MixMix.setup.updateNSFWSetting(true))
              onClose()
              trackClick('enableNSFW', { modalType: 'NSFWPromptModal' })
            }}
            className="btn btn-accent cursor-pointer"
          >
            Enable NSFW
          </button>
        </div>
      </div>
    </Modal>
  )
}
