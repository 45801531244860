import capitalize from 'lodash.capitalize'
import Image from 'next/image'

import { useTheme } from '@context/ThemeContext'
import { useTrackClick } from '@hooks/analytics/useTrackClick'
import { useIsArticle } from '@hooks/useIsArticle'
import { IntoUrl } from '@models/IntoUrl'
import { UrlMetaDebugInfo } from './UrlMetaDebugInfo'

export const UrlMetaLoading = () => (
  <div className="flex animate-pulse flex-col space-y-4">
    <div className="flex items-center space-x-2">
      <div className="size-4 animate-pulse rounded-full bg-contrast/10" />
      <div className="h-4 w-20 animate-pulse bg-contrast/10" />
    </div>
    <div className="flex h-12 flex-col space-y-1">
      <div className="size-full bg-contrast/10" />
      <div className="size-full bg-contrast/10" />
    </div>
  </div>
)

export const UrlMeta = ({ url }: { url: IntoUrl }) => {
  const isArticle = useIsArticle(url)
  const trackClick = useTrackClick()
  const theme = useTheme()
  const isDark = theme.resolvedTheme === 'dark'

  const titleElement: React.ReactNode = (
    <div className="line-clamp-5">
      <p className="text-lg font-medium leading-tight">{url.title}</p>
    </div>
  )

  if (url.meta.isNativeContent) {
    return titleElement
  }

  if (isArticle) return null

  return (
    <a
      href={url.url}
      className="group flex flex-col justify-between space-y-4"
      rel="external nofollow noopener noreferrer"
      target={'_blank'}
      onClick={() => trackClick('urlOpenExternal')}
    >
      <div className="flex select-none justify-between">
        <div className="flex h-4 items-center space-x-2 leading-tight opacity-75">
          <Image
            className="fill-primary"
            src={url.meta.provider.faviconURL}
            style={{ filter: isDark ? 'invert(1)' : 'invert(0)' }}
            alt={url.meta.provider.id}
            width={16}
            height={16}
          />
          <span className="truncate text-[13px] font-medium">{capitalize(url.meta.provider.id)}</span>
        </div>
        <UrlMetaDebugInfo url={url} className="!p-0" />
      </div>
      {titleElement}
    </a>
  )
}
