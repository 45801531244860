import axios from 'axios'
import parse from 'html-react-parser'
import Image from 'next/image'
import Link from 'next/link'
import React, {
  ChangeEvent,
  FocusEvent,
  FormEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import ReactDOM from 'react-dom'
import toast from 'react-hot-toast'
import { useSessionStorage } from 'usehooks-ts'

import Modal, { ChildModalProps } from '@common/Modal'
import { UserListItem } from '@common/UserListItem'
import { useCommandContext } from '@context/CommandContext'
import { useCurrentUserContext } from '@context/CurrentUserContext'
import LoginButton from '@desktop/login/LoginButton'
import { useTimeSince } from '@desktop/notifications/useTimeSince'
import { useRequest } from '@hooks/useRequest'
import IconAlert from '@icons/IconAlert.svg'
import IconArrowMin from '@icons/IconArrowMin.svg'
import IconDelete from '@icons/IconDelete.svg'
import IconHeart from '@icons/IconHeart.svg'
import IconHeartFilled from '@icons/IconHeartFilled.svg'
import IconNo from '@icons/IconNo.svg'
import IconPlus from '@icons/IconPlus.svg'
import IconReply from '@icons/IconReply.svg'
import IconSpeechBubble from '@icons/IconSpeechBubble.svg'
import { globals } from '@lib/messages/protobuf'
import { pluralize } from '@lib/StringHelper'
import UrlHelper from '@lib/UrlHelper'
import { errorToHelpfulMessage } from '@models/APIErrorResponse'
import { IntoUrl } from '@models/IntoUrl'
import { IntoUser } from '@models/IntoUser'
import { UrlComment } from '@models/UrlCommentsResponse'
import IntoAPI from '@services/IntoAPI'
import MixMix from '@services/MixMix'

const { STATE_UNVERIFIED, STATE_DEFAULT_PHOTO } = globals.UserCommentRestrictionReason

const TW_SIZE_FACTOR = 4
const STORAGE_KEY = 'comments_expanded'
const SUGGESTIONS_LIMIT = 4
const MAX_HEIGHT = 200
const GAP = 16

const ConfirmModal = ({
  isVisible,
  onClose,
  onConfirm,
  confirmText,
}: ChildModalProps & { onConfirm: () => void; confirmText: string }) => (
  <Modal isVisible={isVisible} onClose={onClose}>
    <div className="z-10 flex flex-col space-y-3 rounded bg-menu p-6 text-menu">
      <div className="text-lg font-semibold">Are you sure you want to {confirmText.toLowerCase()} this comment?</div>
      <div className="flex justify-center space-x-6 pt-6">
        <button className="btn bg-primary/10" onClick={() => onClose(false)}>
          Cancel
        </button>
        <button onClick={onConfirm} className="btn bg-red-500/10 text-red-500">
          {confirmText}
        </button>
      </div>
    </div>
  </Modal>
)

const CommentReportModal = ({
  isVisible,
  onClose,
  onReport,
}: ChildModalProps & {
  onReport: (context: 'spam' | 'nsfw') => void
}) => {
  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <div className="max-w-screen z-10 flex flex-col space-y-6 rounded-lg bg-neutral-700 py-4 md:max-w-md">
        <div className="space-y-6 p-2">
          <div className="px-4 text-center font-semibold">Report comment</div>
          <div className="flex flex-col items-start">
            <button onClick={() => onReport('spam')} className="p-2 transition-all hover:scale-105">
              This is spam
            </button>
            <button onClick={() => onReport('nsfw')} className="p-2 transition-all hover:scale-105">
              This shows sensitive content
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const UrlCommentItem = ({
  comment,
  imageSize,
  onReplyClick,
  mutate,
  inputRef,
  isReactionEnabled,
}: {
  comment: UrlComment
  imageSize: number
  onReplyClick: (comment: UrlComment) => void
  mutate: () => void
  inputRef: MutableRefObject<HTMLInputElement | null>
  isReactionEnabled: boolean
}) => {
  const { isLoggedIn, currentUser } = useCurrentUserContext()
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [isAdminHideModalVisible, setIsAdminHideModalVisible] = useState(false)
  const [isReportModalVisible, setIsReportModalVisible] = useState(false)
  const timeSince = useTimeSince(comment.created_at)
  const sizeClass = `size-${imageSize / TW_SIZE_FACTOR}`
  const isOwnComment = comment.user_id === currentUser?.user_id
  const { commandContext } = useCommandContext()
  const [isCommentLiked, setIsCommentLiked] = useState(comment.has_user_reacted)
  const [likeCount, setLikeCount] = useState(comment.reaction_count)
  const [isLikeLoading, setIsLikeLoading] = useState(false)
  useEffect(() => {
    setIsCommentLiked(comment.has_user_reacted)
    setLikeCount(comment.reaction_count)
  }, [comment])

  const onLike = useCallback(async () => {
    if (isLikeLoading) return

    setIsLikeLoading(true)
    setIsCommentLiked(isCommentLiked => !isCommentLiked)
    setLikeCount(likeCount => (comment.has_user_reacted ? likeCount - 1 : likeCount + 1))
    const endpoint = comment.has_user_reacted ? IntoAPI.url.unlikeComment : IntoAPI.url.likeComment
    try {
      await axios
        .request(
          endpoint({
            commentID: comment.id,
          })
        )
        .catch(e => {
          toast.error(errorToHelpfulMessage(e))
          setIsCommentLiked(!isCommentLiked)
          setLikeCount(comment.has_user_reacted ? likeCount + 1 : likeCount - 1)
        })
      await mutate()
    } finally {
      setIsLikeLoading(false)
    }
  }, [comment, mutate, isCommentLiked, likeCount, setLikeCount, setIsCommentLiked, isLikeLoading])

  const onReport = useCallback(
    async (context: 'spam' | 'nsfw') => {
      await axios
        .request(
          MixMix.url.reportComment({
            reported_id: comment.id,
            context,
            pageContext: commandContext,
          })
        )
        .then(() => {
          toast.success('Thank you for your report')
        })
        .catch(e => toast.error(errorToHelpfulMessage(e)))
      setIsReportModalVisible(false)
    },
    [comment, commandContext]
  )

  const onDelete = useCallback(async () => {
    await axios
      .request(
        MixMix.url.deleteComment({
          commentID: comment.id,
        })
      )
      .then(() => toast.success('Comment deleted'))
      .catch(e => toast.error(errorToHelpfulMessage(e)))
    await mutate()
  }, [comment, mutate])

  const onAdminHide = useCallback(async () => {
    await axios
      .request(MixMix.admin.comments.hide(comment.id))
      .then(() => toast.success('Comment hidden'))
      .catch(e => toast.error(errorToHelpfulMessage(e)))
    await mutate()
  }, [comment, mutate])

  return (
    <>
      <div className="flex space-x-3">
        <Link className="shrink-0" href={UrlHelper.userPath(comment.username)}>
          <Image
            className={`inline-block aspect-square shrink-0 rounded-full bg-tertiary object-cover ${sizeClass}`}
            src={comment.photo_url ?? ''}
            alt={comment.username ?? ''}
            height={imageSize}
            width={imageSize}
          />
        </Link>
        <div className="flex w-full min-w-0 flex-col space-y-4">
          <div className="flex w-full space-x-1.5">
            <div className="group w-full min-w-0 space-y-1 text-sm leading-tight">
              <div className="flex h-4 items-center justify-between space-x-2">
                <div className="flex space-x-2 truncate">
                  <Link href={UrlHelper.userPath(comment.username)} className="truncate font-medium text-secondary/60">
                    {comment.display_name || comment.username}
                  </Link>
                  <div className="text-xs text-secondary/30">{timeSince}</div>
                </div>
                {/* Comment actions*/}
                <div className="flex items-center space-x-2">
                  {isOwnComment ? (
                    <button
                      onClick={() => setIsDeleteModalVisible(true)}
                      className="text-red-800 opacity-0 transition-all duration-200 hover:scale-125 group-hover:opacity-100"
                      title="Delete Comment"
                    >
                      <IconDelete className="size-4" />
                    </button>
                  ) : isLoggedIn ? (
                    <div className="flex items-center space-x-1">
                      <div className="hidden space-x-1 group-hover:flex">
                        {!currentUser?.meta.commentError && (
                          <button
                            onClick={() => onReplyClick(comment)}
                            className="opacity-50 transition-all duration-200 hover:scale-125"
                            title="Reply to Comment"
                          >
                            <IconReply className="size-5" />
                          </button>
                        )}
                        <button
                          onClick={() => setIsReportModalVisible(true)}
                          className="opacity-50 transition-all duration-200 hover:scale-125"
                          title="Report Comment"
                        >
                          <IconAlert className="size-4" />
                        </button>
                        {currentUser?.isEmployee && (
                          <button
                            onClick={() => setIsAdminHideModalVisible(true)}
                            className="opacity-50 transition-all duration-200 hover:scale-125"
                            title="Hide Comment"
                          >
                            <IconNo className="size-4" />
                          </button>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={`text-primary/90 ${comment.is_large_type ? 'text-2xl' : 'text-sm'} break-anywhere`}>
                {parse(comment.text)}
              </div>
            </div>
            {isReactionEnabled && (
              <div className="flex flex-col items-center space-y-1 text-xs">
                <LoginButton
                  onClickIfLoggedIn={onLike}
                  className="transition-all duration-200 hover:scale-125"
                  copyVariant="conversation"
                >
                  {isCommentLiked ? (
                    <IconHeartFilled className="size-4" />
                  ) : (
                    <IconHeart className="size-4 opacity-50" />
                  )}
                </LoginButton>
                {likeCount > 0 && <div className="opacity-50">{likeCount}</div>}
              </div>
            )}
          </div>
          {comment.replies?.map(reply => (
            <UrlCommentItem
              key={reply.id}
              onReplyClick={() => {
                onReplyClick(comment)
                if (inputRef.current) inputRef.current.value = `@${reply.username} `
              }}
              comment={reply}
              imageSize={24}
              mutate={mutate}
              inputRef={inputRef}
              isReactionEnabled={isReactionEnabled}
            />
          ))}
        </div>
      </div>

      {/* Modals*/}
      <ConfirmModal
        isVisible={isDeleteModalVisible}
        onConfirm={onDelete}
        onClose={() => setIsDeleteModalVisible(false)}
        confirmText="Delete"
      />
      <ConfirmModal
        isVisible={isAdminHideModalVisible}
        onConfirm={onAdminHide}
        onClose={() => setIsAdminHideModalVisible(false)}
        confirmText="Hide"
      />
      <CommentReportModal
        isVisible={isReportModalVisible}
        onReport={onReport}
        onClose={() => setIsReportModalVisible(false)}
      />
    </>
  )
}

const SuggestionsPopover = ({
  query,
  inputRef,
  setQuery,
  urlId,
}: {
  query: string
  inputRef: MutableRefObject<HTMLInputElement | null>
  setQuery: React.Dispatch<React.SetStateAction<string | undefined>>
  urlId: string
}) => {
  const { data: userSearchData } = useRequest(
    IntoAPI.user.search({ query, searchContext: 'url_comments', searchContextUrlId: urlId })
  )
  const searchedUsers: IntoUser[] | undefined = userSearchData?.items.filter(
    (item): item is IntoUser => item.type === 'user'
  )

  const popoverRef = useRef<HTMLDivElement | null>(null)
  const [top, setTop] = useState<number>(0)
  const [left, setLeft] = useState<number>(0)
  const [width, setWidth] = useState<number>(0)
  const [finalHeight, setFinalHeight] = useState<number>(0)

  const handleSuggestionClick = (suggestion: string) => {
    if (!inputRef.current) return
    const atIndex = inputRef.current.value.lastIndexOf('@')
    inputRef.current.value = inputRef.current.value.slice(0, atIndex + 1) + suggestion + ' '
    inputRef.current.focus()
    setQuery(undefined)
  }

  const updatePosition = useCallback(() => {
    if (!inputRef.current || !popoverRef.current || !searchedUsers || searchedUsers.length === 0) return
    const inputRect = inputRef.current.getBoundingClientRect()
    const contentHeight = popoverRef.current.scrollHeight
    let final = Math.min(contentHeight, MAX_HEIGHT)

    // Position the suggestions box below the input
    let calculatedTop = inputRect.bottom + window.scrollY + GAP
    const calculatedLeft = inputRect.left + window.scrollX
    const calculatedWidth = inputRect.width

    // Check if the suggestions box overflows the viewport
    const bottomEdge = calculatedTop + final
    const viewportBottom = window.scrollY + window.innerHeight
    if (bottomEdge > viewportBottom) {
      // If it overflows, position it above the input
      calculatedTop = inputRect.top + window.scrollY - GAP - final
      // Adjust if it still overflows
      if (calculatedTop < window.scrollY) {
        final = inputRect.top + window.scrollY - GAP - window.scrollY
        calculatedTop = window.scrollY
      }
    }

    setTop(calculatedTop)
    setLeft(calculatedLeft)
    setWidth(calculatedWidth)
    setFinalHeight(final)
  }, [inputRef, popoverRef, searchedUsers])

  useEffect(() => {
    updatePosition()
    // Recalculate if window resizes
    window.addEventListener('resize', updatePosition)
    window.addEventListener('scroll', updatePosition, true)
    return () => {
      window.removeEventListener('resize', updatePosition)
      window.removeEventListener('scroll', updatePosition, true)
    }
  }, [updatePosition])

  useEffect(() => {
    if (popoverRef.current) {
      const observer = new MutationObserver(() => {
        updatePosition()
      })
      observer.observe(popoverRef.current, { childList: true, subtree: true })
      return () => {
        observer.disconnect()
      }
    }
  }, [searchedUsers, updatePosition])

  if (!query) return null

  if (!searchedUsers || searchedUsers.length === 0) return null

  return ReactDOM.createPortal(
    <div
      ref={popoverRef}
      className="fixed z-50 flex flex-col space-y-4 overflow-auto rounded-xl bg-input p-4"
      style={{ left, top, width, maxHeight: finalHeight }}
    >
      {searchedUsers.slice(0, SUGGESTIONS_LIMIT).map(user => (
        <button
          className="text-left"
          key={`searched-user-${user.user_id}`}
          onClick={() => handleSuggestionClick(user.username)}
        >
          <UserListItem userId={user.user_id} hideFollowButton={true} preventNavigation={true} />
        </button>
      ))}
    </div>,
    document.body
  )
}

export const UrlComments = ({
  url,
  onClose,
  showDropdown = true,
  className,
}: {
  url: IntoUrl
  onClose?: () => void
  showDropdown?: boolean
  className?: string
}) => {
  const { isLoggedIn, currentUser } = useCurrentUserContext()
  const { data, mutate } = useRequest(MixMix.url.getComments({ url_id: url.url_id }))
  const [isExpanded, setIsExpanded] = useSessionStorage(STORAGE_KEY, true)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [replyComment, setReplyComment] = useState<UrlComment | undefined>(undefined)
  const inputContainerRef = useRef<HTMLDivElement | null>(null)
  const commentErrorReason = currentUser?.meta.commentError?.reason
  const isCommentErrorButtonDisabled =
    commentErrorReason !== STATE_UNVERIFIED && commentErrorReason !== STATE_DEFAULT_PHOTO

  useEffect(() => {
    if (!showDropdown) setIsExpanded(true)
  }, [showDropdown, setIsExpanded])

  const insertEmoji = (emoji: string) => {
    if (inputRef.current) {
      inputRef.current.value += emoji
      inputRef.current.focus()
    }
  }

  const onReplyClick = (comment: UrlComment) => {
    setReplyComment(comment)
    inputRef.current?.focus()
  }

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      if (!inputRef.current || currentUser?.meta.commentError) return
      await axios
        .request(
          MixMix.url.postComment({
            url_id: url.url_id,
            text: inputRef.current.value,
            parent_id: replyComment?.id,
          })
        )
        .catch(e => toast.error(errorToHelpfulMessage(e)))
      inputRef.current.value = ''
      inputRef.current.blur()
      await mutate()
    },
    [url.url_id, replyComment, mutate, currentUser?.meta.commentError]
  )

  const handleBlur = useCallback(
    (event: FocusEvent) => {
      const nextFocusedElement = event.relatedTarget
      if (!inputContainerRef.current?.contains(nextFocusedElement)) {
        setReplyComment(undefined)
      }
    },
    [setReplyComment]
  )
  const emojis = ['😄', '🥰', '😂', '😮', '🔥', '😱', '@']
  const [userSearchQuery, setUserSearchQuery] = useState<string | undefined>(undefined)

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const atIndex = value.lastIndexOf('@')
    const spaceIndex = value.lastIndexOf(' ')

    if (spaceIndex > atIndex) setUserSearchQuery(undefined)
    else if (atIndex !== -1) setUserSearchQuery(value.slice(atIndex + 1))
    else setUserSearchQuery(undefined)
  }

  return (
    <div
      className={`flex ${showDropdown ? 'max-h-[50vh]' : 'max-h-[90vh]'} flex-col rounded-xl bg-contrast/10 p-2 ${className}`}
    >
      <button
        onClick={() => (showDropdown ? setIsExpanded(!isExpanded) : null)}
        className="flex w-full items-center justify-between pr-2"
      >
        <div className="flex items-center text-[13px] font-medium leading-snug tracking-wide">
          <IconSpeechBubble className="size-10 opacity-50" />
          <div>{data?.meta.total && data.meta.total > 0 ? pluralize(data.meta.total, 'Comment') : 'Comments'}</div>
        </div>
        {showDropdown ? (
          <IconArrowMin
            className={`size-3 ${isExpanded ? 'rotate-90' : '-rotate-90'} mr-2 opacity-50 transition-all`}
          />
        ) : (
          onClose && (
            <button onClick={onClose}>
              <IconPlus className="size-5 rotate-45" />
            </button>
          )
        )}
      </button>
      {isExpanded && (
        <>
          <div className={`space-y-4 overflow-y-scroll scrollbar-hide ${data?.items.length ? 'p-2' : 'p-1'}`}>
            {data?.items.map(comment => (
              <UrlCommentItem
                key={comment.id}
                onReplyClick={onReplyClick}
                comment={comment}
                imageSize={36}
                mutate={mutate}
                inputRef={inputRef}
                isReactionEnabled={isCommentErrorButtonDisabled}
              />
            ))}
          </div>
          <div ref={inputContainerRef} className="space-y-3 border-t-[0.5px] border-primary/10 p-2 pt-4">
            {isLoggedIn ? (
              <>
                {!currentUser?.meta.commentError && (
                  <div className="flex justify-center text-center">
                    {emojis.map(emoji => (
                      <button
                        className="w-12 cursor-pointer text-xl font-semibold transition-all duration-200 hover:scale-125"
                        key={`${emoji}-emoji`}
                        onClick={() => insertEmoji(emoji)}
                      >
                        {emoji}
                      </button>
                    ))}
                  </div>
                )}

                <div className="relative flex space-x-3">
                  <Image
                    key={currentUser?.user_id}
                    className="inline-block aspect-square size-9 shrink-0 rounded-full bg-tertiary object-cover"
                    src={currentUser?.photo_url ?? ''}
                    alt={currentUser?.display_name ?? ''}
                    height={36}
                    width={36}
                  />
                  {currentUser?.meta.commentError ? (
                    <button
                      onClick={() => (window.location.href = '/settings')}
                      disabled={isCommentErrorButtonDisabled}
                      className="btn flex h-9 grow items-center justify-center bg-accent py-0 text-xs text-white disabled:bg-tertiary disabled:opacity-50 disabled:hover:scale-100"
                    >
                      {currentUser?.meta.commentError.message}
                    </button>
                  ) : (
                    <form className="w-full" onSubmit={onSubmit}>
                      <input
                        ref={inputRef}
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleInputChange}
                        placeholder={
                          replyComment
                            ? 'Replying to ' + replyComment.display_name
                            : data?.meta.total && data.meta.total > 0
                              ? 'Add a comment...'
                              : 'Be the first to comment...'
                        }
                        className="h-9 w-full rounded-full border-none bg-input p-3 text-sm placeholder:text-input/50"
                      />
                      {userSearchQuery !== undefined && userSearchQuery !== '' && (
                        <SuggestionsPopover
                          query={userSearchQuery}
                          setQuery={setUserSearchQuery}
                          inputRef={inputRef}
                          urlId={url.url_id}
                        />
                      )}
                    </form>
                  )}
                </div>
              </>
            ) : (
              <div className="flex justify-center">
                <LoginButton className="btn btn-primary py-2 font-semibold" copyVariant="conversation">
                  Join the conversation
                </LoginButton>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
